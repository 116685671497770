h1 {
    margin-top: 0px;
    margin-bottom: 15px;
    font-family: clavo, sans-serif;
    color: #004f60;
    font-size: 2.3em;
    line-height: 140%;
    font-weight: 700;
}

h2 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: clavo, sans-serif;
    color: #004f60;
    font-size: 1.7em;
    line-height: 140%;
    font-weight: 700;
}

h3 {
    margin-top: 25px;
    margin-bottom: 10px;
    font-family: clavo, sans-serif;
    color: #004f60;
    font-size: 1.4em;
    line-height: 140%;
    font-weight: 700;
}

h4 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: clavo, sans-serif;
    color: #004f60;
    font-size: 1.2em;
    line-height: 140%;
    font-weight: 700;
}

h5 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: proxima-nova, sans-serif;
    color: #004f60;
    font-size: 1.2em;
    line-height: 150%;
    font-weight: 700;
}

h6 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #004f60;
    font-size: 1.15em;
    line-height: 18px;
    font-weight: 700;
    text-transform: none;
}

p {
    margin-bottom: 10px;
    font-family: proxima-nova, sans-serif;
    color: #373e42;
    font-size: 1.15em;
    line-height: 160%;
    font-weight: 500;
}

a {
    display: inline-block;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    color: #004f60;
    font-size: 1em;
    text-decoration: underline;
}

a:hover {
    display: inline-block;
    background-color: #004f60;
    color: #fff;
}

ul {
    margin-bottom: 10px;
    padding-left: 19px;
    font-family: proxima-nova, sans-serif;
    color: #373e42;
    list-style-type: disc;
}

ol {
    display: block;
    overflow: visible;
    margin-top: 0px;
    margin-bottom: 10px;
    padding-left: 40px;
    list-style-type: disc;
}

li {
    margin-bottom: 5px;
    font-family: proxima-nova, sans-serif;
    font-size: 1.15em;
    line-height: 160%;
    font-weight: 500;
}

img {
    display: inline-block;
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #373e42;
    font-size: 1.15em;
    font-weight: 700;
}

strong {
    color: #424b50;
    font-weight: 700;
}

blockquote {
    margin-bottom: 10px;
    padding: 10px 20px;
    border-left: 5px solid #bfc3c7;
    background-image: url('../images/bracket.gif'), url('../images/bracket.gif');
    background-position: 0px 100%, 0px 0px;
    background-repeat: no-repeat, no-repeat;
    font-family: clavo, sans-serif;
    color: #373e42;
    font-size: 1.2em;
    line-height: 160%;
    font-weight: 400;
}

.navbar {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 5;
    border-bottom: 1px none #000;
    background-color: hsla(0, 0%, 96%, .85);
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, .3);
}

.header-logo {
    position: static;
    right: 0px;
    bottom: 0px;
    width: 240px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.hero-subhead {
    width: 100%;
    margin-bottom: 10px;
    font-family: proxima-nova, sans-serif;
    color: #424b50;
    font-size: 1.35em;
    line-height: 24px;
    font-weight: 400;
    text-align: right;
}

.hero-headline {
    margin-top: 0px;
    margin-bottom: 5px;
    font-family: clavo, sans-serif;
    color: #004f60;
    font-size: 2.7em;
    line-height: 110%;
    font-weight: 700;
    text-align: right;
}

.head-button {
    margin-top: 0px;
    padding-top: 9px;
    float: right;
    border-radius: 4px;
    background-color: #004f60;
    font-family: proxima-nova, sans-serif;
    font-size: 1.15em;
    font-weight: 700;
}

.head-button:hover {
    background-color: #5f6a72;
}

.main-section {
    display: block;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    font-weight: 700;
}

.main-container {
    margin-top: -70px;
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
    background-color: #fff;
}

.body {
    background-color: #fff;
    font-family: proxima-nova, sans-serif;
    color: #373e42;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
}

.dropdown-link {
    display: inline-block;
    width: 100%;
    background-color: transparent;
    -webkit-transition: background-color 100ms ease;
    transition: background-color 100ms ease;
    font-family: proxima-nova, sans-serif;
    color: #424b50;
    font-size: 1em;
    line-height: 1.2em;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
}

.dropdown-link:hover {
    background-color: #004f60;
    color: #fff;
    font-weight: 700;
}

.dropdown-list {
    background-color: hsla(0, 0%, 100%, .8);
}

.dropdown-list:hover {
    color: #004f60;
}

.dropdown-list.w--open {
    z-index: 4;
    display: block;
    background-color: hsla(0, 0%, 96%, .85);
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, .3);
    direction: ltr;
    font-family: proxima-nova, sans-serif;
    color: #424b50;
    text-transform: none;
}

.dropdown-list.w--open:hover {
    border-style: none;
}

.footer {
    overflow: visible;
    margin-top: 45px;
    padding-top: 20px;
    padding-bottom: 70px;
    border-style: none;
    background-color: #f5f5f5;
    background-image: -webkit-linear-gradient(290deg, #f5f5f5 45%, #dbdbdb);
    background-image: linear-gradient(160deg, #f5f5f5 45%, #dbdbdb);
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, .3);
    color: #004f60;
}

.dropdown {
    -webkit-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
    font-family: proxima-nova, sans-serif;
    color: transparent;
    font-weight: 700;
}

.dropdown:hover {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: transparent;
}

.suche {
    margin-bottom: -10px;
    color: #424b50;
}

.footer-logo {
    text-align: center;
}

.footer-text-link {
    display: inline-block;
    background-color: transparent;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    direction: ltr;
    font-family: proxima-nova, sans-serif;
    color: #424b50;
    font-size: 1em;
    line-height: 140%;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    list-style-type: none;
}

.footer-text-link:hover {
    background-color: #004f60;
    color: #fff;
}

.footer-text-link.w--current {
    position: static;
    display: block;
    overflow: visible;
    float: left;
    clear: none;
    line-height: 140%;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
}

.footer-copyright-text {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: proxima-nova, sans-serif;
    color: #004f60;
    font-size: 1em;
    text-align: center;
}

.icons {
    display: inline-block;
    width: 32px;
    height: 32px;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
}

.icons:hover {
    background-color: transparent;
}

.icons.xing {
    background-image: url('../images/Xing.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
}

.icons.xing:hover {
    background-image: url('../images/Xing-hv.png');
    background-position: 50% 50%;
    background-size: 32px;
}

.icons.linkedin {
    background-image: url('../images/LinkedIn.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.linkedin:hover {
    background-image: url('../images/LinkedIn-hv.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.google {
    background-image: url('../images/Google+.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.google:hover {
    background-image: url('../images/Google+-hv.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.facebook {
    background-image: url('../images/Facebook.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.facebook:hover {
    background-image: url('../images/Facebook-hv.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.twitter {
    background-image: url('../images/Twitter.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.twitter:hover {
    background-image: url('../images/Twitter-hv.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.youtube {
    background-image: url('../images/YouTube.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.youtube:hover {
    background-image: url('../images/YouTube-hv.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.slideshare {
    background-image: url('../images/Slideshare.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.slideshare:hover {
    background-image: url('../images/Slideshare-hv.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.flickr {
    background-image: url('../images/Flickr.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.flickr:hover {
    background-image: url('../images/Flickr-hv.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.instagram:hover {
    background-image: url('../images/Instagram-hv.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.icons.instagram {
    background-image: url('../images/Instagram.png');
    background-position: 50% 50%;
    background-size: 32px;
    background-repeat: no-repeat;
}

.footer-tel {
    display: inline-block;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    color: #004f60;
    font-size: 1em;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
}

.footer-tel:hover {
    background-color: #004f60;
    color: #fff;
}

.newsletter-field {
    width: 60%;
    height: 40px;
    margin-top: 0px;
    float: left;
    border: 1px solid #004f60 !important;
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
    background-color: #fff !important;
    font-family: proxima-nova, sans-serif;
    color: #424b50 !important;
    font-size: 1em;
    font-weight: 400;
    padding: 8px 12px !important;
}

.newsletter-field:hover {
    border-color: #004f60;
}

.newsletter-submit {
    display: block;
    width: 40%;
    height: 40px;
    border: 1px none #000;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color: #004f60;
    font-family: proxima-nova, sans-serif;
    color: #fff;
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
}

.newsletter-submit:hover {
    background-color: #424b50;
}

.newsletter-form {
    margin-top: 0px;
    margin-bottom: -12px;
    padding-top: 0px;
}

.footer-text-plain-nl {
    padding-top: 0px;
    padding-bottom: 5px;
    font-family: proxima-nova, sans-serif;
    color: #424b50;
    font-size: 1em;
    text-align: left;
}

.photo-container {
    margin-top: -30px;
}

.sharing-button {
    display: inline-block;
    margin-top: -45px;
    margin-right: 10px;
    float: right;
    border-radius: 30px;
    background-color: #016fb9;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, .3);
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
}

.sharing-button:hover {
    background-color: #004f60;
}

.navigation {
    background-color: transparent;
}

.navigation-menu {
    margin-top: 67px;
}

.nav-link-menu {
    padding: 10px 15px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    -webkit-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
    font-family: proxima-nova, sans-serif;
    color: #424b50;
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
}

.nav-link-menu:hover {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #004f60;
    color: #fff;
}

.nav-link-menu.divider {
    display: inline-block;
    padding-right: 20px;
}

.nav-link-menu.divider:hover {
    border-top-right-radius: 0px;
    background-color: transparent;
    color: #424b50;
}

.dropdown-toggle {
    padding: 10px 30px 0px 15px;
    -webkit-transition: background-color 100ms ease;
    transition: background-color 100ms ease;
    color: #424b50;
}

.dropdown-toggle:hover {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #004f60;
    color: #fff;
}

.dropdown-toggle:hover a {
    color: #fff;
}

.dropdown-icon {
    margin-top: 12px;
    margin-right: 15px;
}

.dropdown-nav-link {
    display: block;
    padding-right: 4px;
    padding-bottom: 10px;
    color: #424b50;
    font-size: 1em;
    text-transform: uppercase;
}

.dropdown-nav-link:hover {
    color: #fff;
}

.dropdown-nav-link > a.nav-link-menu {
    padding: 0;
    text-decoration: none;
}

.header-link:hover {
    background-color: transparent;
    color: transparent;
}

.overhead {
    padding-top: 20px;
    font-family: proxima-nova, sans-serif;
    color: #373e42;
    font-size: 1.2em;
    line-height: 120%;
    font-weight: 400;
    text-transform: uppercase;
}

.starter {
    margin-bottom: 40px;
    font-family: proxima-nova, sans-serif;
    color: #373e42;
    font-size: 1.3em;
    line-height: 160%;
    font-weight: 700;
}

.footer-row {
    margin-top: 20px;
}

.footer-head {
    margin-top: 15px;
    font-family: clavo, sans-serif;
    font-size: 1.15em;
    font-weight: 700;
    text-transform: uppercase;
}

.footer-p {
    color: #373e42;
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
}

.footer-social-icons {
    display: block;
    margin-top: 0px;
}

.social-media-icons-list {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    float: left;
    list-style-type: none;
}

.footer-logo-div {
    text-align: left;
}

.footer-col-left {
    padding-left: 10px;
}

.sidebar-link {
    display: inline-block;
    margin-bottom: 10px;
    font-family: proxima-nova, sans-serif;
    color: #373e42;
    line-height: 120%;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
}

.kontakt-button {
    position: absolute;
    top: 0px;
    right: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color: #5f6a72;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    font-family: proxima-nova, sans-serif;
    color: #fff;
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
}

.kontakt-button.w--current {
    background-color: #5f6a72;
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.kontakt-button.w--current:hover {
    background-color: #004f60;
}

.titel {
    margin-bottom: 20px;
    font-family: clavo, sans-serif;
    color: #004f60;
    font-size: 1.2em;
    line-height: 150%;
    font-weight: 700;
}

.footer-column-mid {
    padding-left: 10px;
}

.breadcrumb-text {
    display: inline;
    padding-right: 0px;
    padding-left: 0px;
    color: #5f6a72;
    font-size: 0.75em;
    text-transform: uppercase;
}

.breadcrumb-list {
    margin-bottom: 0px;
    font-family: proxima-nova, sans-serif;
    color: #5f6a72;
    list-style-type: none;
}

.breadcrumb-link {
    display: inline;
    color: #5f6a72;
    font-size: 0.75em;
    line-height: 120%;
    text-decoration: none;
    text-transform: uppercase;
    list-style-type: none;
}

.breadcrumb-item {
    display: inline;
    margin-bottom: 0px;
    font-weight: 400;
}

.breadcrumb-arrow {
    display: inline;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 0.75em;
}

.footer-list {
    padding-left: 0px;
    list-style-type: none;
}

.footer-item {
    display: block;
    font-size: 1em;
    line-height: 120%;
}

.bildunterschrift {
    margin-bottom: 20px;
    font-family: clavo, sans-serif;
    color: #373e42;
    font-size: 0.95em;
    font-style: italic;
    font-weight: 400;
}

.slider {
    margin-top: 20px;
    margin-bottom: 10px;
}

.slider-image {
    margin-top: 0px;
    margin-bottom: 0px;
}

.pull-quote {
    width: 30%;
    margin-left: 40px;
    float: right;
    font-style: italic;
}

.hero-section {
    height: 450px;
    padding-top: 200px;
    /*background-image: url('../images/_P9F3328_2.jpg');*/
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-section.rechtsgebiet {
    background-image: url('../images/schatten.jpg');
    background-position: 50% 40%;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-section.roemermann_vita {
    background-image: url('../images/_P9F0519_ret2_2.jpg');
    background-position: 0% 70%;
}

.contentcolumn {
    padding-right: 40px;
}

.infocolumn {
    padding-top: 8px;
}

.ansprechpartner-klammer {
    display: inline-block;
    width: 100%;
    height: 30px;
    max-width: 100%;
    margin-bottom: -35px;
    background-color: #004f60;
}

.weiterlesen-button {
    display: inline;
    margin-bottom: 10px;
    padding: 5px 15px;
    border-radius: 5px;
    background-color: #016fb9;
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    font-size: 0.9em;
    text-align: center;
    text-transform: uppercase;
}

.weiterlesen-button:hover {
    display: inline;
}

.nl-success {
    font-size: 1em;
}

.nl-error {
    line-height: 120%;
}

.navigation-sidebar {
    padding-left: 0px;
    font-size: 0.95em;
    text-transform: uppercase;
    list-style-type: none;
}

.sidebar-navigation-links {
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
    -webkit-transition-property: none;
    transition-property: none;
    color: #373e42;
    text-decoration: none;
}

.sidebar-navigation-links:hover {
    display: block;
    background-color: transparent;
}

.sidebar-navigation-list-item {
    display: block;
    margin-left: -2px;
    padding-left: 2px;
    border-bottom: 1px solid #dbd7d7;
    -webkit-transition: background-color 100ms ease;
    transition: background-color 100ms ease;
    font-size: 1.05em;
    line-height: 140%;
}

.sidebar-navigation-list-item:hover {
    background-color: #004f60;
}

.sidebar-head {
    margin-top: 23px;
    margin-bottom: 10px;
    color: #004f60;
    font-size: 1.2em;
    font-weight: 700;
}

.content-head {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: clavo, sans-serif;
    color: #004f60;
    font-size: 1.1em;
    line-height: 140%;
    font-weight: 700;
    text-decoration: none;
}

.content-head:hover {
    background-color: transparent;
    color: #373e42;
}

.content-copy {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 0.9em;
    line-height: 140%;
}

.related-div {
    width: 292px;
    margin: 10px 20px 10px 10px;
    padding: 10px 10px 17px;
    float: left;
    border: 1px solid #dbd7d7;
    box-shadow: none;
    -webkit-transition: box-shadow 100ms ease, -webkit-transform 100ms ease;
    transition: box-shadow 100ms ease, -webkit-transform 100ms ease;
    transition: transform 100ms ease, box-shadow 100ms ease;
    transition: transform 100ms ease, box-shadow 100ms ease, -webkit-transform 100ms ease;
    line-height: 90%;
}

.related-div:hover {
    box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, .3);
    -webkit-transform: scale(1.005);
    -ms-transform: scale(1.005);
    transform: scale(1.005);
}

.related-list-item {
    margin-left: -10px;
    float: left;
}

.related-category {
    display: inline;
    padding-right: 8px;
    font-size: 0.7em;
    text-transform: uppercase;
}

.related-category-link {
    text-decoration: none;
}

.ansprechpartner-image {
    margin-top: 0px;
    margin-bottom: -23px;
}

.related-image-link {
    margin-bottom: 10px;
    -webkit-transition-property: none;
    transition-property: none;
}

.related-image-link:hover {
    background-color: transparent;
}

.sidebar-list-item2 {
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
}

.sidebar-div {
    width: 210px;
    margin-right: 20px;
    border: 1px solid #dbd7d7;
    background-color: #fff;
    -webkit-transition: box-shadow 100ms ease, -webkit-transform 100ms ease;
    transition: box-shadow 100ms ease, -webkit-transform 100ms ease;
    transition: transform 100ms ease, box-shadow 100ms ease;
    transition: transform 100ms ease, box-shadow 100ms ease, -webkit-transform 100ms ease;
    text-align: center;
}

.sidebar-div:hover {
    box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, .3);
    -webkit-transform: scale(1.005);
    -ms-transform: scale(1.005);
    transform: scale(1.005);
}

.sidebar-head-link {
    display: inline-block;
    width: 89%;
    margin-right: 10px;
    margin-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    -webkit-transition-property: none;
    transition-property: none;
    font-family: clavo, sans-serif;
    line-height: 120%;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    list-style-type: none;
}

.sidebar-head-link:hover {
    background-color: #fff;
    color: #373e42;
}

.sidebar-button {
    display: inline-block;
    margin-bottom: 10px;
    padding: 0px 5px;
    border-radius: 2px;
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    font-size: 0.8em;
    list-style-type: none;
}

.sidebar-button:hover {
    text-decoration: none;
}

.divider-line {
    margin-bottom: 10px;
    border-bottom: 1px solid #dbd7d7;
    font-weight: 500;
    text-transform: uppercase;
}

.autor-list {
    /*float: left;*/
}

.autor-image {
    width: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    float: left;
}

.autor-head-link {
    display: inline-block;
    margin-bottom: 7px;
    font-family: clavo, sans-serif;
    font-size: 0.95em;
    font-weight: 700;
    text-decoration: none;
}

.autor-head-link:hover {
    background-color: transparent;
    color: #004f60;
    text-decoration: underline;
}

.sidebar-list {
    margin-bottom: 30px;
    padding-left: 0px;
    border-bottom: 1px solid #dbd7d7;
    list-style-type: none;
}

.sidebar-list-item {
    margin-bottom: 15px;
    border-bottom: 1px none #dbd7d7;
    font-size: 1em;
    font-weight: 400;
    text-decoration: none;
}

.sidebar-list-item-head {
    display: inline-block;
    font-family: clavo, sans-serif;
    font-weight: 700;
    text-transform: none;
}

.sidebar-titel-link {
    display: inline-block;
    margin-top: -2px;
    color: #373e42;
    font-size: 1em;
    text-decoration: none;
    text-transform: none;
}

.sidebar-titel-link:hover {
    background-color: transparent;
    color: #004f60;
    text-decoration: underline;
}

.sidebar-weitere {
    /*display: block;*/
    font-size: 1em;
    font-weight: 700;
    text-decoration: underline;
}

.sidebar-mehr-link {
    font-weight: 700;
}

.sidebar-overhead {
    font-size: 0.9em;
    text-transform: uppercase;
}

.overview-link:hover {
    background-color: transparent;
}

.overview-image {
    margin-top: 0px;
    margin-bottom: 0px;
}

.overview-image-list-item {
    width: 32%;
    float: left;
}

.overview-content-list-item {
    width: 68%;
    padding-left: 15px;
    float: right;
}

.overview-titel-link {
    display: inline-block;
    margin-bottom: 10px;
    font-family: clavo, sans-serif;
    line-height: 148%;
    font-weight: 700;
    text-decoration: none;
}

.overview-titel-link:hover {
    background-color: transparent;
    color: #004f60;
    text-decoration: underline;
}

.overview-overhead {
    display: block;
    margin-bottom: 5px;
    font-size: 0.8em;
    line-height: 100%;
    text-transform: uppercase;
}

.overview-text {
    font-size: 0.9em;
    line-height: 150%;
}

.overview-mehr-link {
    font-weight: 700;
}

.overview-list {
    margin-bottom: 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dbd7d7;
}

.autor-content-list-item {
    width: 75%;
    padding-left: 20px;
    float: right;
}

.autor-link:hover {
    background-color: transparent;
}

.autor-image-list-item {
    width: 25%;
    margin-bottom: 5px;
    float: left;
}

.autor-text {
    font-size: 0.9em;
    line-height: 150%;
}

.start-container {
    margin-top: -70px;
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
    background-color: #fff;
}

.opener {
    margin-top: 15px;
    margin-bottom: 20px;
    color: #373e42;
    font-size: 1.4em;
    line-height: 180%;
    font-weight: 500;
    text-align: left;
}

.home-rechtsgebiete-text {
    font-size: 1.15em;
    line-height: 160%;
    font-weight: 500;
}

.home-rechtsgebiete {
    margin-bottom: 35px;
    padding-top: 10px;
    border-top: 1px solid #dbd7d7;
    background-color: #eff0f1;
}

.pagination-list a {
    color: #373e42;
    text-decoration: none;
    display: block;
}

.pagination-list-item.aktiv a {
    color: #fff;
}

.pagination-list-item:hover {
    background-color: #424b50 !important;
    color: #fff !important;
}

.pagination-list a:hover {
    color: #fff !important;
    background-color: transparent !important;
}

.pagination-list-item {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    float: left;
    border-radius: 3px;
    font-size: 1em;
    line-height: 210%;
    font-weight: 500;
    text-align: center;
}

.pagination-list-item:hover {
    background-color: #004f60;
    color: #fff;
}

.pagination-list-item.aktiv {
    background-color: #424b50;
    color: #fff;
    font-size: 1em;
}

.home-kontakt-column {
    margin-top: 45px;
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 20px;
    border-left: 1px solid #dbd7d7;
    text-align: right;
}

.home-kontakt-p {
    margin-top: 3px;
    margin-bottom: 5px;
    font-size: 1em;
    line-height: 120%;
}

.home-starter-column {
    margin-bottom: 15px;
}

.home-starter-row {
    margin-bottom: 20px;
}

.mehr-erfahren-button {
    display: inline;
    margin-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 5px;
    background-color: #016fb9;
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    font-size: 0.9em;
    text-transform: uppercase;
}

.mehr-erfahren-button:hover {
    display: inline;
}

.home-kontakt-links {
    margin-bottom: 4px;
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    color: #373e42;
    font-size: 1em;
    line-height: 120%;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
}

.home-kontakt-subhead {
    margin-top: 10px;
    margin-bottom: 2px;
    font-size: 1em;
}

.home-kontakt-head {
    margin-top: 4px;
    color: #004f60;
    font-size: 1.2em;
}

.home-rechtsgebiete-column {
    padding-bottom: 10px;
}

.home-starter-div {
    margin-bottom: 45px;
}

.home-image-small {
    margin-bottom: -10px;
}

.home-aktuelles-column-left {
    padding-top: 5px;
}

.home-newsletter-image {
    margin-top: 0px;
}

.home-newsletter-text {
    margin-top: 7px;
    margin-bottom: 8px;
}

.home-veroeffentlichungen-column {
    padding-top: 7px;
}

.home-newsletter-column {
    padding-top: 7px;
    border-bottom: 1px solid #dbd7d7;
    background-color: #eff0f1;
    background-image: none;
}

.home-head2 {
    margin-top: 30px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dbd7d7;
    font-family: clavo, sans-serif;
    color: #004f60;
    font-size: 1.7em;
    line-height: 140%;
}

.home-head3 {
    margin-bottom: 10px;
    border-bottom: 1px solid #dbd7d7;
    font-family: clavo, sans-serif;
    color: #004f60;
    font-size: 1.4em;
    line-height: 140%;
}

.related-image {
    margin-top: 0px;
    margin-bottom: 0px;
}

.profil-button {
    margin-top: 10px;
    padding: 1px 15px;
    border-radius: 5px;
    background-color: #016fb9;
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    font-size: 0.9em;
    text-align: center;
    text-transform: uppercase;
}

.publikationen-row {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dbd7d7;
}

.publikationen-row .text-right {
    text-align: right;
    padding-right: 0 !important;
}

.publikationen-tabelle-rubrik {
    font-size: 1em;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
}

.publikationen-tabelle-text {
    font-size: 1em;
    line-height: 130%;
    font-weight: 500;
    padding-right: 5px;
}

.publikationen-field {
    display: block;
    margin-right: 15px;
    float: left;
}

.divider-line.publikation {
    margin-top: 30px;
}

.filtern-button {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 5px;
    background-color: #016fb9;
    text-transform: uppercase;
}

.filtern-button:hover {
    display: inline-block;
    text-transform: uppercase;
}

.publikationen-form {
    margin-right: -11px;
    margin-bottom: 30px;
    margin-left: -10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    border-bottom: 1px solid #dbd7d7;
    background-color: #eff0f1;
    padding-bottom: 20px;
}

.hero-image {
    position: static;
    margin-top: 0px;
    margin-bottom: 0px;
}

.hero-div {
    width: 100%;
    height: 100%;
}

.hero-container {
    display: none;
}

.blog-overview-header {
    margin-top: 0px;
    margin-bottom: -35px;
}

.blog-overview-header:hover {
    background-color: #fff;
}

.blog-overview-link {
    color: transparent;
}

.blog-overview-list {
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 20px;
    padding-left: 0px;
    background-color: #fff;
    -webkit-perspective: 1px;
    perspective: 1px;
}

.blog-overview-content-list-item {
    margin-bottom: 10px;
    padding: 20px;
}

.blog-overview-div {
    margin-bottom: 40px;
    border: 1px solid #dbd7d7;
    box-shadow: 1px 1px 8px 0 #dbd7d7;
}

.blog-overview-mehr-link {
    margin-top: 10px;
    font-size: 0.95em;
    line-height: 150%;
    font-weight: 700;
}

.blog-overview-overhead {
    font-size: 0.9em;
    line-height: 110%;
}

.blog-overview-titel-link {
    padding-top: 2px;
    padding-bottom: 10px;
    font-family: clavo, sans-serif;
    font-size: 1.25em;
    line-height: 140%;
    font-weight: 700;
    text-decoration: none;
}

.blog-overview-titel-link:hover {
    background-color: transparent;
    color: #004f60;
    text-decoration: underline;
}

.blog-overview-text {
    margin-top: 10px;
    font-size: 0.9em;
    font-weight: 400;
}

.blog-overview-info-text {
    color: #5f6a72;
    font-size: 0.75em;
    line-height: 160%;
    font-weight: 400;
    text-transform: uppercase;
}

.blog-overview-social-media-buttons {
    margin-top: 20px;
    margin-bottom: 0px;
}

.blog-overview-info-link {
    color: #5f6a72;
    text-decoration: none;
}

.blog-overview-info-link:hover {
    background-color: transparent;
    color: #5f6a72;
    text-decoration: underline;
}

html.w-mod-js *[data-ix="display-none"] {
    display: none;
}

html.w-mod-js *[data-ix="thumbnail"] {
    opacity: 0;
    -webkit-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
}

.search-modal {
    display: none;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(245, 245, 245, 0.85);
}

.search-modal-inner {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    margin-top: 180px;
}

.search-modal-close {
    position: absolute;
    top: 18px;
    right: 20px;
    height: 30px;
    width: 30px;
    font-size: 22px;
    color: #373e42;
}

.search-modal-close:hover {
    background-color: transparent;
    color: #004f60;
}

.search-modal-headline {
    font-weight: 700;
    font-family: clavo, sans-serif;
    letter-spacing: 0.06em;
    color: #004f60;
    font-size: 2.3em;
    margin-bottom: 0;
}

.search-modal-tip {
    font-family: proxima-nova, sans-serif;
    margin-top: 5px;
    font-size: 1.2em;
    font-weight: 400;
    color: #373e42;
}

.search-modal-input {
    outline: none;
    background: none;
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0;
    color: #373e42;
    font-size: 74px;
    font-weight: 100;
    border-bottom: 1px solid #016fb9;
    width: 100%;
}

i.menu-icons-style {
    cursor: pointer;
    width: 17px;
    height: 17px;
    color: #424b50;
    padding: 0;
    margin-top: 12px;
    font-size: 17px;
}

.social-button {
    float: right;
    margin-top: -45px;
    margin-right: 15px;
}

.social-button a:hover {
    background-color: #004f60;
}

.social-button ul.mfb-component--tr {
    position: relative;
    margin: 0;
}

.social-button .mfb-component__button--main {
    width: 50px;
    height: 50px;
}

.social-button .mfb-component__button--main {
    background-color: #016fb9;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, .3);
}

.social-button .mfb-component__main-icon--resting {
    font-size: 28px;
    line-height: 54px;
}

.social-button .mfb-component__button--child {
    height: 50px;
    width: 50px;
    background-color: #004f60;
}

.social-button .mfb-component__list > li {
    margin: 2px 0;
    line-height: 20px;
}

@media (max-width: 991px) {
    .navbar {
        z-index: 10;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .header-logo {
        width: 170px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .hamburger-menu {
        color: #424b50;
    }

    .hamburger-menu:hover {
        background-color: transparent;
        color: #004f60;
    }

    .navigation-menu {
        display: block;
        width: auto;
        margin-top: 8px;
        padding-top: 8px;
        background-color: hsla(0, 0%, 96%, .85);
        color: #373e42;
        font-size: 1em;
    }

    .dropdown-toggle {
        display: block;
    }

    .dropdown-nav-link {
        display: block;
    }

    .hamburger-menu-button:hover {
        color: #fff;
    }

    .contentcolumn {
        margin-top: -35px;
        padding-top: 20px;
    }
}

@media (max-width: 767px) {
    .header-logo {
        margin-left: 20px;
    }

    .footer {
        padding-right: 20px;
        padding-left: 20px;
    }

    .footer-logo {
        margin-left: 10px;
    }

    .sharing-button {
        display: inline;
    }

    .hamburger-menu-button {
        margin-right: 20px;
    }

    .pull-quote {
        width: 40%;
    }

    .contentcolumn {
        padding-right: 0px;
        padding-left: 0px;
    }

    .infocolumn {
        padding-right: 0px;
        padding-left: 0px;
    }

    .overview-image-list-item {
        width: 40%;
    }

    .overview-content-list-item {
        width: 60%;
    }

    .autor-content-list-item {
        width: 70%;
    }
    .autor-image-list-item {
        width: 30%;
    }

    .filtern-button {
        margin-left: 10px;
    }

    .publikationen-form {
        padding-right: 0px;
        padding-left: 0px;
    }
}

@media (max-width: 479px) {
    .header-logo {
        margin-left: 5px;
    }

    .main-container {
        padding-right: 10px;
        padding-left: 10px;
    }

    .body {
        font-size: 0.75em;
    }

    .footer {
        padding-right: 0px;
        padding-left: 0px;
    }

    .footer-copyright-text {
        padding-right: 15px;
        padding-left: 15px;
    }

    .overhead {
        padding-top: 0px;
    }

    .starter {
        line-height: 160%;
    }

    .footer-row {
        padding-right: 15px;
        padding-left: 15px;
    }

    .footer-logo-div {
        padding-left: 5px;
    }

    .footer-col-left {
        padding-right: 0px;
        padding-left: 0px;
    }

    .footer-column-mid {
        padding-right: 0px;
        padding-left: 0px;
    }

    .footer-column-right {
        padding-right: 0px;
        padding-left: 0px;
    }

    .breadcrumb-text {
        display: inline;
    }

    .breadcrumb-link {
        display: inline;
        line-height: 100%;
    }

    .breadcrumb-item {
        display: inline;
        line-height: 100%;
    }

    .breadcrumb-arrow {
        display: inline;
    }

    .pull-quote {
        width: 100%;
        margin-left: 10px;
        padding-right: 10px;
    }

    .contentcolumn {
        padding-right: 5px;
        padding-left: 5px;
    }

    .infocolumn {
        padding-right: 5px;
        padding-left: 5px;
    }

    .overview-image-list-item {
        width: auto;
    }

    .overview-content-list-item {
        width: auto;
        margin-top: 10px;
        padding-left: 0px;
    }
    .blog-overview-header {
        margin-bottom: 0px;
    }
    .blog-overview-list {
        margin-right: 0px;
        margin-left: 0px;
    }
    .blog-overview-content-list-item {
        padding: 10px;
    }
    .blog-overview-text {
        font-size: 1em;
    }
}

.publikationen-form .w-input, .publikationen-form .w-select {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #333333;
    vertical-align: middle;
    border: none;
}

.selectize-input {
    border: 1px solid #cccccc;
    padding: 6px 12px;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    background-image: linear-gradient(white 0%, #f3f3f3 100%) !important;
    background-image: -webkit-linear-gradient(white 0%, #f3f3f3 100%) !important;
}

.selectize-input.focus {
  box-shadow: none !important;
  border-color: #004F60 !important;
}

p.cc_message {
    color: #ccc !important;
}

.cc_container .cc_btn, .cc_container .cc_btn:visited {
    color: #fff !important;
    background-color: #016fb9 !important;
}

.cc_container .cc_btn:hover{
    color: #000 !important;
    background-color: #fff !important;
}


.cc_container a, .cc_container a:visited, .cc_container a:hover {
    color: #fff !important;
}

.cc_container {
    background: #5f6a72 !important;
}

[v-cloak] { display: none }


.shariff .theme-grey .shariff-button a {
    background-color: #8f969c;
}

.share-link, .share-link:hover {
    display: block;
}

.w--nav-link-open:hover {
    display: block;
}

#share {
    margin-top: 40px;
}

.downloads-row {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dbd7d7;
}

/* Shariff overwrite */

@media only screen and (min-width: 768px) {
    .shariff .orientation-horizontal li {
        min-width: 90px !important;
        max-width: 160px;
    }
}

ul.language-switch {
    position: absolute;
    right: 110px;
    top: 4px;
}

ul.language-switch li {
    display: inline-block;
    list-style: none;
    font-weight: 400;
}

ul.language-switch li:after {
    content: "|";
    padding-left: 4px;
}
/*
ul.language-switch li:first-child:after {
    content: "";
}
*/
ul.language-switch li:last-child:after {
    content: "";
}

ul.language-switch li a {
    color: #373e42;
    text-decoration: none;
}

ul.language-switch li a:hover {
    color: #fff;
}

div.footer-menu {
    display: inline;
}

div.footer-menu span:after {
    content: "•";
}

div.footer-menu span:last-child:after {
    content: "";
}